import api from '../../../api';
import styles from './TutorialModal.module.css';
import { useEffect, useState } from 'react';

const TutorialModal = ({setTutorialModalPage, setAuthModalPage, setInfoModalPage, account, getMyInfo, marginTop }) => {
    const [phase, setPhase] = useState(0);
    const [finishFlag, setFinishFlag] = useState(false);
    const [isRotating, setIsRotating] = useState(false); // 회전 상태 관리
    const [visible, setVisible] = useState(false);
    
    const handleLaterButtonClicked = () => {
        localStorage.setItem('skipTutorial', true);
        setTutorialModalPage(0);
    };

    const handleTutorialFinishClicked = () => {
        if(phase==6 && !finishFlag){
            localStorage.setItem('skipTutorial', true);
            setFinishFlag(true);
            api.patch(`/user/tutorial`);
            setTutorialModalPage(0);
            setInfoModalPage(64);
        }
    };

    const handleRefreshClick = () => {
        if (isRotating) return; // 클릭 방지
        setIsRotating(true); // 회전 시작

        getMyInfo();

        setTimeout(() => {
            setIsRotating(false);
        }, 1000);
    };

    useEffect(()=> {
        setPhase(account.tutorial_phase);
    }, [account.tutorial_phase]);

    useEffect(() => {
        setVisible(true);
        setPhase(account.tutorial_phase);
    }, []);

  	return (
        <div className={`${styles.modalbackground} ${visible ? styles.visible : ''}`}>
            <div className={`${styles.modalcontainer} ${visible ? styles.visible : ''}`} style={{maxHeight: `calc(100dvh - ${marginTop + 24}px)`, marginTop: `${marginTop}px`}}>
                <div className={styles.logoutmodalParent}>
                    <div className={styles.logoutmodal}>
                        <div className={styles.container}>
                            <div className={styles.hedaer}>
                                        <b className={styles.mainheader}>
                                            <img className={styles.hotple2Icon} alt="" src="images/HOTPLE.svg" />
                                            <span className={styles.span}>튜토리얼</span>
                                        </b>
                                    <div className={styles.mainheader1}>
                                        <p className={styles.hotple}>이것만 따라하면 나도 HOTPLE 마스터!</p>
                                        <p className={styles.hotple}>간단한 튜토리얼 완료하고 XP도 받아가세요~</p>
                                    </div>
                                    <div className={styles.parent}>
                                        <div className={styles.div}>
                                                <div className={phase == 1 ? styles.numbering : styles.numbering1}>
                                                    <div className={styles.div1}>1</div>
                                                </div>
                                                <div className={styles.text}>
                                                    <div className={styles.group}>
                                                            <div className={styles.div2}>핫플 계정 생성</div>
                                                            <div className={phase == 1 ? styles.tagpoint : styles.tagpoint1}>
                                                                <div className={phase <= 1 ? styles.p : styles.pFinish}>100XP</div>
                                                            </div>
                                                    </div>
                                                    {phase==1 && <div className={styles.div3}>20초 소요</div>}
                                                </div>
                                                {phase==1 ?
                                                <div className={styles.buttondefault} onClick={()=>setAuthModalPage(11)}>
                                                    <div className={styles.div1}>시작하기</div>
                                                </div>
                                                :
                                                <div className={styles.buttondefault1}>
                                                    <div className={styles.div1}>{phase > 1 ? "완료" : "-"}</div>
                                                </div>
                                                }
                                        </div>
                                        <div className={styles.div}>
                                                <div className={phase == 2 ? styles.numbering : styles.numbering1}>
                                                    <div className={styles.div1}>2</div>
                                                </div>
                                                <div className={styles.text2}>
                                                    <div className={styles.group}>
                                                            <div className={styles.div2}>인스타 계정 연결</div>
                                                            <div className={phase == 2 ? styles.tagpoint : styles.tagpoint1}>
                                                                <div className={phase <= 2 ? styles.p : styles.pFinish}>200XP</div>
                                                            </div>
                                                    </div>
                                                    {phase==2 && <div className={styles.div3}>30초 소요</div>}
                                                </div>
                                                {phase==2 ?
                                                <div className={styles.buttondefault} onClick={()=>setAuthModalPage(3)}>
                                                    <div className={styles.div1}>시작하기</div>
                                                </div>
                                                :
                                                <div className={styles.buttondefault1}>
                                                    <div className={styles.div1}>{phase > 2 ? "완료" : "-"}</div>
                                                </div>
                                                }
                                        </div>
                                        <div className={styles.div}>
                                                <div className={phase == 3 ? styles.numbering : styles.numbering1}>
                                                    <div className={styles.div1}>3</div>
                                                </div>
                                                <div className={styles.text2}>
                                                    <div className={styles.group}>
                                                            <div className={styles.div2}>인스타 AI 스크랩</div>
                                                            <div className={phase == 3 ? styles.tagpoint : styles.tagpoint1}>
                                                                <div className={phase <= 3 ? styles.p : styles.pFinish}>300XP</div>
                                                            </div>
                                                    </div>
                                                    {phase==3 && <div className={styles.div3}>30초 소요</div>}
                                                </div>
                                                {phase==3 ?
                                                <div className={styles.buttondefault} onClick={()=>setInfoModalPage(61)}>
                                                    <div className={styles.div1}>시작하기</div>
                                                </div>
                                                :
                                                <div className={styles.buttondefault1}>
                                                    <div className={styles.div1}>{phase > 3 ? "완료" : "-"}</div>
                                                </div>
                                                }
                                        </div>
                                        <div className={styles.div}>
                                                <div className={phase == 4 ? styles.numbering : styles.numbering1}>
                                                    <div className={styles.div1}>4</div>
                                                </div>
                                                <div className={styles.text2}>
                                                    <div className={styles.group}>
                                                            <div className={styles.div2}>핫플 검토</div>
                                                            <div className={phase == 4 ? styles.tagpoint : styles.tagpoint1}>
                                                                <div className={phase <= 4 ? styles.p : styles.pFinish}>400XP</div>
                                                            </div>
                                                    </div>
                                                    {phase==4 && <div className={styles.div3}>30초 소요</div>}
                                                </div>
                                                {phase==4 ?
                                                <div className={styles.buttondefault} onClick={()=>setInfoModalPage(62)}>
                                                    <div className={styles.div1}>시작하기</div>
                                                </div>
                                                :
                                                <div className={styles.buttondefault1}>
                                                    <div className={styles.div1}>{phase > 4 ? "완료" : "-"}</div>
                                                </div>
                                                }
                                        </div>
                                        <div className={styles.div}>
                                                <div className={phase == 5 ? styles.numbering : styles.numbering1}>
                                                    <div className={styles.div1}>5</div>
                                                </div>
                                                <div className={styles.text2}>
                                                    <div className={styles.group}>
                                                            <div className={styles.div2}>친구 태그 AI 스크랩</div>
                                                            <div className={phase == 5 ? styles.tagpoint : styles.tagpoint1}>
                                                                <div className={phase <= 5 ? styles.p : styles.pFinish}>500XP</div>
                                                            </div>
                                                    </div>
                                                    {phase==5 && <div className={styles.div3}>50초 소요</div>}
                                                </div>
                                                {phase==5 ?
                                                <div className={styles.buttondefault} onClick={()=>setInfoModalPage(63)}>
                                                    <div className={styles.div1}>시작하기</div>
                                                </div>
                                                :
                                                <div className={styles.buttondefault1}>
                                                    <div className={styles.div1}>{phase > 5 ? "완료" : "-"}</div>
                                                </div>
                                                }
                                        </div>
                                    </div>
                                    <div className={styles.buttons}>
                                        <div className={phase == 6 ? styles.positiveButton : styles.positiveButtonDisabled} onClick={handleTutorialFinishClicked}>
                                            <div className={styles.text1}>{phase<7 ? "튜토리얼 완료 보상" : "튜토리얼 완료"}</div>
                                            <div className={phase == 6 ? styles.point : styles.pointDisabled}>1000XP</div>
                                        </div>
                                        {phase < 6 && account.hotpleId &&
                                        <div className={styles.positiveButton2} onClick={handleRefreshClick}>
                                            <div className={`${styles.text3} ${isRotating ? styles.rotate : ""}`}>↻</div>
                                        </div>
                                        }
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.wrapper} onClick={handleLaterButtonClicked}>
                        <div className={styles.div19}>나중에 할래요</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
    
export default TutorialModal;
  