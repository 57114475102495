const { isMobile } = require("react-device-detect");

function josa(word, josaType) {
    const lastChar = word.charAt(word.length - 1);
    const lastCharCode = lastChar.charCodeAt(0);
    
    // 한글의 유니코드 범위: 0xAC00 ~ 0xD7A3
    // 한글의 종성 여부는 (유니코드 - 0xAC00) % 28 값으로 판별 가능
    const hasFinalConsonant = (lastCharCode - 0xAC00) % 28 !== 0;

    switch (josaType) {
        case '은는':
            return (hasFinalConsonant ? '은' : '는');
        case '이가':
            return (hasFinalConsonant ? '이' : '가');
        case '와과':
            return (hasFinalConsonant ? '과' : '와');
        case '을를':
            return (hasFinalConsonant ? '을' : '를');
        default:
            return ''; // 입력한 조사 유형이 없을 경우, 그대로 반환
    }
}

const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      await window.flutter_inappwebview.callHandler('CustomChannel', 'COPY_TO_CLIPBOARD', text.toString());
    } catch (err) {
      console.error("Copy to clipboard failed");
    }
};
  
// 공유 기능을 호출하는 함수
const shareContent = async ( title, text ) => {
    if (navigator.share && isMobile) {
      try {
        await navigator.share({
          title: title,
          text: text
        });
      } catch (err) {
        console.error('공유 실패: ', err);
      }
    } else if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler('CustomChannel', 'SHARE_REQUEST', text);
    } else {
      // 공유 기능을 사용할 수 없는 경우 클립보드에 복사
      await copyToClipboard(text);
      alert('핫플 URL 링크가 클립보드에 복사되었어요!\n함께 하고 싶은 친구에게 공유해볼까요?');
    }
};

const getImageFile = () => {
  return new Promise((resolve, reject) => {
      // 파일 선택을 위한 input element 생성
      const inputElement = document.createElement('input');
      inputElement.type = 'file';
      inputElement.accept = 'image/*'; // 이미지만 선택할 수 있도록 제한

      // 파일 선택이 완료되면 파일명과 파일 객체를 함께 반환
      inputElement.onchange = (event) => {
          const file = event.target.files[0];
          if (file) {
              const filePath = file.name; // 경로를 포함한 형식으로 반환
              resolve({ filePath, file });
          } else {
              reject(new Error('No file selected'));
          }
      };

      // 파일 선택 창을 강제로 띄움
      inputElement.click();
  });
};

const getFiles = () => {
  return new Promise((resolve, reject) => {
      // 파일 선택을 위한 input element 생성
      const inputElement = document.createElement('input');
      inputElement.type = 'file';
      inputElement.accept = 'image/*,video/*'; // 이미지와 영상 파일 모두 선택 가능
      inputElement.multiple = true; // 여러 개 파일 선택 가능

      inputElement.onchange = (event) => {
          const files = Array.from(event.target.files);
          if (files.length > 0) {
              resolve(files); // 선택한 파일들을 배열로 반환
          } else {
              reject(new Error('No files selected'));
          }
      };

      inputElement.click(); // 파일 선택 창을 띄움
  });
};

module.exports = {
    josa,
    shareContent,
    copyToClipboard,
    getImageFile,
    getFiles
};